import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import PlentyHeading from '#app/components/typography/plenty-heading'

const HelpLink = ({ to, label }: { to: string; label: string }) => (
	<Link to={to} className="group w-fit cursor-pointer">
		<PlentyButtonsAndLinks
			as="nav-sm"
			className="border-b border-b-transparent underline group-hover:border-black-90 group-focus:border-mint-green-50 md:no-underline"
		>
			{label}
		</PlentyButtonsAndLinks>
	</Link>
)

const QuickHelpLinks = () => {
	const { t } = useTranslation('footer')

	return (
		<div className="flex flex-col gap-4">
			<div>
				<PlentyHeading as={'h7'}>{t('get_help')}</PlentyHeading>
			</div>
			<div className="flex flex-col gap-2">
				<HelpLink to="/faq" label={t('faq')} />
				<HelpLink
					to="/click-and-collect-and-delivery"
					label={t('shipping_&_delivery')}
				/>
				<HelpLink to="/returns-and-refunds" label={t('returns_&_refunds')} />
			</div>
		</div>
	)
}

export default QuickHelpLinks
