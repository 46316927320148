import { useTranslation } from 'react-i18next'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'

const SocialMediaIconBundle = () => {
	let { t } = useTranslation('footer')

	return (
		<div className="flex flex-col gap-4">
			<div className="flex gap-2">
				<PlentyButtonsAndLinks as={'nav-sm'}>
					{t('find_us_so_me')}
				</PlentyButtonsAndLinks>

				<a
					href="https://www.instagram.com/plentyand_/"
					target="_blank"
					rel="noopener noreferrer"
					className="group flex items-center gap-2"
				>
					<Icon className="text-body-md" size="sm" name="instagram" />
				</a>
				<a
					href="https://www.linkedin.com/company/plentyand/"
					target="_blank"
					rel="noopener noreferrer"
					className="group flex items-center gap-2"
				>
					<Icon className="text-body-md" size="sm" name="linkedin" />
				</a>
				<a
					href="https://www.pinterest.dk/plentyand/"
					target="_blank"
					rel="noopener noreferrer"
					className="group flex items-center gap-2"
				>
					<Icon className="text-body-md" size="sm" name="pinterest" />
				</a>
				<a
					href="https://www.facebook.com/profile.php?id=100089802061958"
					target="_blank"
					rel="noopener noreferrer"
					className="group flex items-center gap-2"
				>
					<Icon className="text-body-md" size="sm" name="facebook" />
				</a>
			</div>
		</div>
	)
}

export default SocialMediaIconBundle
