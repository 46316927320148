import PlentyBody from '#app/components/typography/plenty-body'
import LanguageModal from '#app/features/localisation/language-modal'

const FooterCopyrightAndLanguage = () => {
	return (
		<div className="md:justify-auto flex justify-between gap-6">
			<div >
				<LanguageModal />
			</div>
			<div>
				<PlentyBody size={'sm'}>Plenty&</PlentyBody>
			</div>
			<div>
				<PlentyBody
					size={'sm'}
				>{`© ${new Date().getFullYear()} All Rights Reserved`}</PlentyBody>
			</div>
		</div>
	)
}
export default FooterCopyrightAndLanguage
