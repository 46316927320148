import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'

const PolicyLink = ({ to, label }: { to: string; label: string }) => (
	<Link to={to} className="group w-fit cursor-pointer ">
		<PlentyBody
			size="sm"
			className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
		>
			{label}
		</PlentyBody>
	</Link>
)

const Separator = () => <div>|</div>

const PolicyLinks = () => {
	const { t } = useTranslation('footer')

	return (
		<div className="flex items-center justify-between md:gap-10">
			<PolicyLink
				to="/policy/terms-and-condition"
				label={t('terms_&_conditions')}
			/>
			<Separator />
			<PolicyLink to="/policy/privacy" label={t('privacy_policy')} />
			<Separator />
			<PolicyLink to="/policy/cookie" label="Cookies" />
		</div>
	)
}

export default PolicyLinks
